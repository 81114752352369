import request from "@/plugins/axios";

// 用户信息
export const apiInformationUser = (params: any) =>
    request.get("/statistics_information.information/statistics", { params });

// 用户绑定券
export const apiInformationVouchersinfo= (params: any) =>
    request.get("/statistics_information.information/vouchers_info", { params });

// 积分
export const apiInformationPointsinfo= (params: any) =>
    request.get("/statistics_information.information/points_info", { params });

export const apiInformationCouponinfo= (params: any) =>
    request.get("/statistics_information.information/coupon_info", { params });

// 订单
export const apiInformationOrderinfo = (params: any) =>
    request.get("/statistics_information.information/order_info", { params });

// 线下核销订单信息
export const apiInformationVerificationinfo= (params: any) =>
    request.get("/statistics_information.information/verification_info", { params });