var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.userTab && _vm.userTab.length > 0)?_c('div',{},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.userTab),function(userInfo,index){return _c('el-tab-pane',{key:index,attrs:{"label":userInfo.name,"name":index + ''}},[_c('div',{staticClass:"userinfo flex"},[_c('el-image',{staticClass:"avatar",attrs:{"src":userInfo.avatar}}),_c('div',{staticClass:"info-list"},[_c('p',[_c('span',[_vm._v("绑定手机号:")]),_vm._v(_vm._s(userInfo.mobile))]),_c('p',[_c('span',[_vm._v("用户昵称:")]),_vm._v(_vm._s(userInfo.nickname))]),_c('p',[_c('span',[_vm._v("用户ID:")]),_vm._v(_vm._s(userInfo.id))]),_c('p',[_c('span',[_vm._v("用户状态:")]),_vm._v(_vm._s(userInfo.disable == 0 ? "正常" : "停用")+" ")])])],1)])}),1)],1):_c('div',[_c('el-empty',{attrs:{"description":"暂无数据","image-size":80}})],1),_c('el-divider'),_c('div',[_c('p',{staticClass:"m-b-12 m-t-12"},[_vm._v(" 绑定券: 共计"+_vm._s(_vm.card_pager.lists.length)+"张 ")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.card_pager.loading),expression:"card_pager.loading"}],attrs:{"data":_vm.card_pager.lists,"size":"mini","border":""}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('el-empty',{attrs:{"description":"暂无数据","image-size":80}})],1),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"card_number","label":"券号"}}),_c('el-table-column',{attrs:{"prop":"profile_type","label":"产品类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.profile_type == 1)?_c('span',[_vm._v(" 电影次票券 ")]):_vm._e(),(row.profile_type == 2)?_c('span',[_vm._v(" 指定品类储值券 ")]):_vm._e(),(row.profile_type == 3)?_c('span',[_vm._v(" N选1兑换券 ")]):_vm._e(),(row.profile_type == 4)?_c('span',[_vm._v(" 指定商品储值券5 ")]):_vm._e(),(row.profile_type == 5)?_c('span',[_vm._v(" 全场通用储值券 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"related_main_category_desc","label":"主品类"}}),_c('el-table-column',{attrs:{"prop":"discount_rate","label":"商城折扣率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.discount_rate)+" % ")]}}])}),_c('el-table-column',{attrs:{"prop":"ticket_price","label":"票品价格档"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ticket_price)+" % ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"次票信息"}},[_c('el-table-column',{attrs:{"align":"center","prop":"coupon_sum","label":"初始票数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"use_count","label":"剩余票数"}})],1),_c('el-table-column',{attrs:{"align":"center","label":"票面金额"}},[_c('el-table-column',{attrs:{"align":"center","prop":"price_count","label":"初始金额"}}),_c('el-table-column',{attrs:{"align":"center","prop":"price_use_count","label":"余额"}})],1),_c('el-table-column',{attrs:{"prop":"invalid_time","label":"有效期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeFormat(row.invalid_time, "yyyy-mm-dd hh:MM:ss"))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 0)?_c('span',[_vm._v(" 未使用 ")]):_vm._e(),(row.status == 1)?_c('span',[_vm._v(" 已使用 ")]):_vm._e(),(row.status == 2)?_c('span',[_vm._v(" 已过期 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"applicable_type_desc","label":"适用城市"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popover',{attrs:{"placement":"top-start","title":"适用城市","width":"200","trigger":"hover","content":row.applicable_type_desc}},[_c('span',{staticClass:"line-1 cursor-pointer",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(row.applicable_type_desc)+" ")])])]}}])})],1),_c('div',{staticClass:"m-t-20 flex row-right"},[_c('ls-pagination',{on:{"change":function($event){return _vm.getCardLists()}},model:{value:(_vm.card_pager),callback:function ($$v) {_vm.card_pager=$$v},expression:"card_pager"}})],1)],1),_c('div',[_c('p',{staticClass:"m-b-12 m-t-12"},[_vm._v(" 积分发放: 共计 "+_vm._s(_vm.point_pager.lists.length)+" 批次 ")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.point_pager.loading),expression:"point_pager.loading"}],attrs:{"data":_vm.point_pager.lists,"size":"mini","border":""}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('el-empty',{attrs:{"description":"暂无数据","image-size":80}})],1),_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"integral_sn","label":"积分批次号"}}),_c('el-table-column',{attrs:{"prop":"name","label":"适用商城名称"}}),_c('el-table-column',{attrs:{"prop":"integral","label":"调整积分"}}),_c('el-table-column',{attrs:{"prop":"index","label":"积分有效期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.start_time)+" ~ "+_vm._s(row.end_time)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"surplus","label":"积分余额"}}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"}})],1),_c('div',{staticClass:"m-t-20 flex row-right"},[_c('ls-pagination',{on:{"change":function($event){return _vm.getPointLists()}},model:{value:(_vm.point_pager),callback:function ($$v) {_vm.point_pager=$$v},expression:"point_pager"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }