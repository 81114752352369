


























































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
    apiInformationVouchersinfo,
    apiInformationPointsinfo,
} from "@/api/statistics";
import { RequestPaging } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
import AfterSalesPane from "@/components/order/after-sales-pane.vue";
import LsPagination from "@/components/ls-pagination.vue";
import { timeFormat } from "@/utils/util";

@Component({
    components: {
        AfterSalesPane,
        ExportData,
        LsPagination,
    },
})
export default class AfterSales extends Vue {
    // S Data
    @Prop() userTab: any;
    @Prop() id: any;
    @Prop() sid: any;
    @Prop() platform_business_id: any;
    @Prop() user_id: any;
    
    timeFormat = timeFormat;
    form: any = {};
    activeName = "0";
    point_pager = new RequestPaging();
    card_pager = new RequestPaging();
    // E Data
    // S Methods
    getCardLists(page?: number): void {
        page && (this.card_pager.page = page);
        this.card_pager
            .request({
                callback: apiInformationVouchersinfo,
                params: {
                    sid: this.sid,
                    id: this.id,
                    ...this.form,
                },
            })
            .then((res) => {});
    }
    getPointLists(page?: number): void {
        page && (this.point_pager.page = page);
        this.point_pager
            .request({
                callback: apiInformationPointsinfo,
                params: {
                    sid: this.sid,
                    id: this.id,
                    platform_business_id: this.platform_business_id,
                    user_id: this.user_id,
                    ...this.form,
                },
            })
            .then((res) => {});
    }
    handleClick(tab: any) {
        this.$emit("change", this.userTab[tab.index]);
    }

    resetName() {
        this.activeName = "0";
    }

    // E Methods

    // S  life cycle

    created() {}

    // E life cycle
}
