




























































import { Component, Vue } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import LsPagination from "@/components/ls-pagination.vue";
import cardPane from "./card_pane.vue";
import couponPane from "./coupon_pane.vue";
import orderPane from "./order_pane.vue";
import hxorderPane from "./hx_order_pane.vue";
import { apiInformationUser } from "@/api/statistics";
@Component({
    components: {
        ExportData,
        LsPagination,
        cardPane,
        couponPane,
        orderPane,
        hxorderPane,
    },
})
export default class AfterSales extends Vue {
    $refs!: {
        card_pane: any;
        coupon_pane: any;
        order_pane: any;
        hx_order_pane: any;
    };
    form: any = {
        type: "1",
        keyword: "",
    };
    sid = "";
    id = "";
    platform_business_id = "";
    user_id = "";
    loading = false;
    userTab: any = [];
    changeInfo(user: any) {
        this.sid = user.sid;
        this.id = user.id;
        this.platform_business_id = user.platform_business_id;
        this.user_id = user.user_id;
        this.$nextTick(() => {
            this.$refs.card_pane.getCardLists(1);
            this.$refs.card_pane.getPointLists(1);
            this.$refs.coupon_pane.getLists(1);
            this.$refs.order_pane.getLists(1);
            this.$refs.hx_order_pane.getLists(1);
        });
    }
    getInfo(): void {
        this.loading = true;
        this.$refs.card_pane.resetName();
        apiInformationUser(this.form).then((res) => {
            this.userTab = res;
            this.loading = false;
            this.sid = "";
            this.id = "";
            if (this.userTab.length > 0) {
                this.sid = this.userTab[0].sid;
                this.platform_business_id =
                    this.userTab[0].platform_business_id;
                this.user_id = this.userTab[0].user_id;
                this.id = this.userTab[0].id;
            }
            this.$nextTick(() => {
                this.$refs.card_pane.getCardLists(1);
                this.$refs.card_pane.getPointLists(1);
                this.$refs.coupon_pane.getLists(1);
                this.$refs.order_pane.getLists(1);
                this.$refs.hx_order_pane.getLists(1);
            });
        });
    }
}
