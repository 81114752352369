





























import { Component, Vue, Prop } from "vue-property-decorator";
import { apiInformationCouponinfo } from "@/api/statistics";
import { RequestPaging } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";
import AfterSalesPane from "@/components/order/after-sales-pane.vue";
import LsPagination from "@/components/ls-pagination.vue";

@Component({
    components: {
        AfterSalesPane,
        ExportData,
        LsPagination,
    },
})
export default class AfterSales extends Vue {
    // S Data
    @Prop() sid: any;
    @Prop() id: any;

    form: any = {};

    pager = new RequestPaging();

    // E Data

    // S Methods
    getLists(page?: number): void {
        page && (this.pager.page = page);
        this.pager
            .request({
                callback: apiInformationCouponinfo,
                params: {
                    sid: this.sid,
                    id: this.id,
                    ...this.form,
                },
            })
            .then((res) => {});
    }

    // E Methods

    // S  life cycle

    created() {}

    // E life cycle
}
